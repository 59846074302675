<template>
  <q-page padding>
    <q-banner
      rounded
      transition-show="jump-down"
      class="bg-info text-white"
    >
      <p class="text-h6">Seja bem vindo!</p>
      <p v-if="user">
        <b>{{ user.user_metadata.name }}</b>.
      </p>
      <template v-slot:action>
        <q-btn
          flat
          color="white"
          label="Voltar"
          :to="{name: 'login'}"
          icon="arrow_back"
        />
      </template>
    </q-banner>
  </q-page>
</template>
<script>
import { defineComponent } from 'vue'
import useAuthUser from '../composables/UseAuthUser'

export default defineComponent({
  name: 'PageMe',

  setup () {
    const { user } = useAuthUser()

    return { user }
  }
})
</script>
